import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { getAuth } from 'firebase/auth';
import { NotificationService } from 'src/app/services/notification.service';
import { PGService } from 'src/app/services/pg.service';
import { Checkout } from 'capacitor-razorpay';
import { ConfigService } from 'src/app/services/config.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-razorpay',
  templateUrl: './razorpay.component.html',
  styleUrls: ['./razorpay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RazorpayComponent implements OnInit {

  @Input() params: Map<string, any>;
  @Output() onPaymentDone = new EventEmitter<{ isSuccess: boolean, data?: any }>();
  amount: number = 0;
  for: string = '';
  email: string = '';
  contact: string = '';
  state: string = '';
  rzpConfig : any;

  constructor(
    private notificationService: NotificationService,
    private pgService: PGService,
    private configService: ConfigService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {

    this.state = 'Initiating the payment';
    this.amount = this.params.get('amount');
    this.for = this.params.get('for');
    this.email = this.params.get('email');
    this.contact = this.params.get('contact');
    this.rzpConfig = this.configService.getProp('razorpay');
    this.createOrder();

  }

  async createOrder() {
    let pgPayload = {
      amount: this.amount * 100, // razorpay need the amount in paisa
      currency: "INR",
      notes: {
        uid: getAuth().currentUser.uid,
        time: (new Date()).getTime(),
        paymentId: this.params.get('paymentId')
      }
    };
    await this.notificationService.showLoading('Initiating payment');
    this.pgService.createOrder(pgPayload)
      .subscribe(
        async (data: any) => {
          await this.notificationService.dismissLoading();
          if (data instanceof HttpResponse) {
            if (data.status == 200) {
              this.checkOut(data.body);
            }
            else {
              this.notificationService.showToastwithoutOK(`It seems successful but not enough data`);
            }
          }
          else {
            this.notificationService.showToastwithoutOK(`It seems successful but not correct type`);
          }
        },
        async (error: any) => {
          await this.notificationService.dismissLoading();
          if (error instanceof HttpErrorResponse) {
            this.notificationService.showToastwithoutOK(error.message);
          }
          else {
            this.notificationService.showToastwithoutOK(`Some unknown error ${error.message}`);
          }
        }
      );
  }

  async checkOut(orderResponse: any) {
    this.state = 'User doing the payment';
    const options = {
      key: this.rzpConfig.keyid,
      amount: orderResponse.amount,
      description: this.for,
      image: this.configService.getProp('iconUrl'),
      order_id: orderResponse.id,
      currency: orderResponse.currency,
      name: this.configService.getProp('iconName'),
      prefill: {
        email: this.email,
        contact: this.contact
      },
      theme: {
        color: '#3399cc'
      }
    }
    try {
      let data = (await Checkout.open(options));
      this.verifyPayment(data.response, orderResponse.id);
    } catch (error) {
      let errorObj;
      try {
        errorObj = JSON.parse(error['code']);
      } catch (error) {
        console.error(`Unable to parse error : `, error);
      }
      this.modalCtrl.dismiss(errorObj, 'failure');
    }
  }

  async verifyPayment(checkoutResponse: any, order_id: string) {
    this.state = 'Verifying the payment';
    await this.notificationService.showLoading('Verifying payment');
    let payLoad = {
      orderId: order_id,
      razorpayPaymentId: checkoutResponse.razorpay_payment_id,
      razorpaySignature: checkoutResponse.razorpay_signature
    };
    this.pgService.verifyPayment(payLoad)
      .subscribe(
        async (data: any) => {
          await this.notificationService.dismissLoading();
          if (data instanceof HttpResponse) {
            if (data.status == 200) {
              this.modalCtrl.dismiss(payLoad, 'success');
              this.onPaymentDone.emit({ isSuccess: true, data: payLoad });
            }
            else {
              this.notificationService.showToastwithoutOK(`It seems successful but not enough data`, true, 4000);
              this.onPaymentDone.emit({ isSuccess: false });
            }
          }
          else {
            this.notificationService.showToastwithoutOK(`It seems successful but not correct type`, true, 4000);
            this.onPaymentDone.emit({ isSuccess: false });
          }
        },
        async (error: any) => {
          await this.notificationService.dismissLoading();
          if (error instanceof HttpErrorResponse) {
            this.notificationService.showToastwithoutOK(error.message);
          }
          else {
            this.notificationService.showToastwithoutOK(`Some unknown error ${error.message}`, true);
          }
          this.onPaymentDone.emit({ isSuccess: false });
        }
      );
  }

}
