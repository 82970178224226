import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { concatMap, delay, repeat, retry, retryWhen, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';
import { IPhysio } from '../models/i-physio';
import { ConfigService } from './config.service';
import { IPatient } from '../models/i-patient';
import { NotificationService } from './notification.service';
import { getAuth } from 'firebase/auth';
import { User } from '../models/i-user';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  accessToken: string;
  bookingState: Subject<number>;
  problemsMap: Map<string, Observable<any>>;
  FAQs$: Observable<any>;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private notificationService: NotificationService
  ) {
    console.debug("UserService contructor");
    this.bookingState = new Subject<number>();
    this.problemsMap = new Map<string, Observable<any>>;
  }

  async getProblemByType(type: string) {
    this.accessToken = await this.getToken();
    if (!this.problemsMap.has(type)) {
      let data$: Observable<any>;
      data$ = this.httpClient.get(environment.firebaseConfig.databaseURL + '/' + type + '.json?auth=' + this.accessToken)
        .pipe(
          tap(console.log),
          shareReplay(1),
          tap(() => {
            // a log to notify it is after shareReplay
          })
        );
      this.problemsMap.set(type, data$);
      return data$;
    }
    return this.problemsMap.get(type);
  }

  async getToken() {
    return await firebase.auth().currentUser.getIdToken();
  }

  findPhysios(patient: IPatient) {
    if (!patient || !patient.location || !patient.location.latitude || !patient.location.longitude
      || !this.configService.getProp("searchRadius") || !this.configService.getUrl("findPhysios")) {
      return throwError({ messsage: "Insufficent data to submit", status: 400 });
    }
    const jsonReq = {
      location: {
        latitude: patient.location.latitude,
        longitude: patient.location.longitude
      },
      radius: this.configService.getProp("searchRadius")
    };
    return this.httpClient.post(this.configService.getUrl("findPhysios"),
      jsonReq, { observe: 'response' });

  }

  public getUsers(ids: string[]): Observable<any> {
    if (!ids || ids.length == 0) {
      return throwError({ messsage: "Insufficent data to proceed", status: 400 });
    }
    const jsonReq = {
      userIds: ids
    };
    return this.httpClient.post(this.configService.getUrl("getUsers"),
      jsonReq, { observe: 'response' });
  }

  public bookingConfirmation(physio: IPhysio, patient: IPatient, paymentId: string, booking: any) {
    this.manageBooking(physio, patient, booking, paymentId);
  }

  getBookingState(): Observable<number> {
    return this.bookingState.asObservable();
  }

  manageBooking(physio: IPhysio, patient: IPatient, booking: any, paymentId: string) {
    const bookingPayload = {
      action: 'CREATE',
      payload: {
        userId: getAuth().currentUser.uid,
        physioId: physio.id,
        type: 'Consultation',
        date: booking.dateInMs,
        createdOn: (new Date()).getTime(),
        slot: booking.slotNo,
        status: this.configService.getProp('paymentMode') && this.configService.getProp('paymentMode') === 'offline' ? 'PaymentDue' : 'Active',
        payments: [paymentId]
      }
    };
    const booking$: Observable<any> = this.httpClient.post(this.configService.getUrl("bookingHandler"), bookingPayload);
    booking$.pipe(
      tap(() => {
        console.debug('booking call executed');
      })
    )
      .subscribe(
        (res: any) => {
          console.debug('booking id ' + res.id);
          this.bookingState.next(0);
          this.managePatient(physio, patient, booking, res.id);
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            this.notificationService.showToastwithoutOK(`Error : ${err.message}`, true);
          }
          console.error(`Something is wrong in creating booking`, err);
        },
      );
  }

  managePatient(physio: IPhysio, patient: IPatient, booking: any, bookingId: string) {
    let updatedPatient = Object.assign({
      status: this.configService.getProp('paymentMode') && this.configService.getProp('paymentMode') === 'offline' ? 'PaymentDue' : 'Active',
      physioId: physio.id,
      bookingId: bookingId,
      userId: getAuth().currentUser.uid,
      createdOn: (new Date()).getTime(),
      consultation: {
        date: booking.dateInMs,
        slot: booking.slotNo,
        status: this.configService.getProp('paymentMode') && this.configService.getProp('paymentMode') === 'offline' ? 'PaymentDue' : 'Active'
      }
    }, patient);

    const patientPayload = {
      action: 'CREATE',
      payload: updatedPatient
    };

    const patient$: Observable<any> = this.httpClient.post(this.configService.getUrl("patientHandler"), patientPayload);
    patient$.pipe(
      tap(() => {
        console.debug('patient call executed');
      })
    )
      .subscribe(
        (res: any) => {
          console.debug('patient id ' + res.id);
          this.bookingState.next(1);
          setTimeout(() => { this.bookingState.next(99) }, 2000); // send 99 to finish the flow
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            this.notificationService.showToastwithoutOK(`Error : ${err.message}`, true);
          }
          console.error(`Something is wrong in creating booking`, err);
          this.notificationService.showToastwithoutOK(`Something is wrong in creating booking`, true);
        },
      );
  }

  //sendNotification(physio: IPhysio, patient: IPatient, payment: any, booking: any, patientId: string) {
  sendNotification(bookingId: string, booking: any) {
    const notificationPayload = {
      action: 'NEW_CONSULTATION',
      payload: {
        bookingId: bookingId,
        dateWithTime: `${this.getSlotDescription(booking.slotNo)} ${this.getDateInString(booking.dateInMs)}`
      }
    };
    this.httpClient.post(this.configService.getUrl("manageNotifications"), notificationPayload, { observe: 'response' })
      .subscribe(
        (res: any) => {
          if (res instanceof HttpResponse) {
            if (res.status === 200) {
              this.bookingState.next(3);
              setTimeout(() => {
                this.bookingState.next(99);
              }, 1500);
            }
          }
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            this.notificationService.showToastwithoutOK(`Error : ${err.message}`, true);
          }
          console.error(`Something is wrong in sending notification`, err);
          this.notificationService.showToastwithoutOK(`Something is wrong in sending notification`, true);
        },
      );
  }

  getBookings() {
    const bookingPayload = {
      action: 'READ',
      payload: {
        start: 0,
        end: 5
      }
    };
    return this.httpClient.post(this.configService.getUrl("bookingHandler"),
      bookingPayload, { observe: 'response' });
  };

  getPayments() {
    const paymentPayload = {
      action: 'READ',
      payload: {
        start: 0,
        end: 5
      }
    };
    return this.httpClient.post(this.configService.getUrl("paymentHandler"),
      paymentPayload, { observe: 'response' });
  };

  getPaymentById(paymentId: string) {
    const paymentPayload = {
      action: 'READ_BY_ID',
      payload: {
        paymentId: paymentId
      }
    };
    return this.httpClient.post(this.configService.getUrl("paymentHandler"),
      paymentPayload, { observe: 'response' });
  };

  getPhoneNumberByWaId(waId: string) {
    const otplessPayload = {
      action: 'GET_PHONENUMBER',
      payload: {
        waId: waId
      }
    };
    return this.httpClient.post(this.configService.getUrl("manageOtp"),
      otplessPayload, { observe: 'response' });
  };

  updateUser(properties: any) {
    const manageUsersPayload = {
      action: 'UPDATE',
      payload: properties
    };
    return this.httpClient.post(this.configService.getUrl("manageUser"),
      manageUsersPayload, { observe: 'response' })
      .pipe(delay(3000), this.handleRetryError(3000));
      
  }

  handleRetryError(delayTime: number) {
    let retries = 0;
    let exceedAttemptLimit = 3;
    return retryWhen((error) => {
      return error.pipe(
        tap(
          () => {
            console.debug(`retrying ${retries}`);
          }
        ),
        delay(delayTime),
        concatMap((err) => {
          retries = retries + 1;
          if (retries < exceedAttemptLimit) {
            return of(err);
          } else {
            throw err;
          }
        })
      );
    });
  }

  getSlotDescription(slotId: number): string {
    const slots: any[] = this.configService.getSlots();
    return `${slots[slotId].from} - ${slots[slotId].to}`;
  }

  getDateInString(dateinMs: number): string {
    const date = new Date(dateinMs);
    return this.getDateInDDMMYYY(date);
  }

  getDateInDDMMYYY(date: Date): string {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return `${date.getDate()}${months[date.getMonth()]}${date.getFullYear()}`;
  }

  getPatient(patientIds: string[]) {
    const patientPayload = {
      action: 'READ_BY_IDs',
      payload: {
        patientIds: patientIds
      }
    };

    return this.httpClient.post(this.configService.getUrl("patientHandler"),
      patientPayload, { observe: 'response' });

  }

  public getAssessments(patient: IPatient, type: string): Observable<any> {
    if (!this.configService.getUrl("patientHandler")) {
      this.notificationService.showToastwithoutOK("Insufficient data at client to proceed");
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }
    let jsonReq = {
      action: 'READ_ASSESSMENT',
      payload: {
        patientId: patient.id,
        type: type
      }
    };
    return this.httpClient.post(this.configService.getUrl("patientHandler"),
      jsonReq, { observe: 'response' });
  }

  public async updatePushToken() {
    if (!this.notificationService.pushToken) {
      console.error("No push token")
      this.notificationService.showToastwithoutOK(`We cannot send notifications`, true);
      return;
    }

    let userProps = {
      pushToken: this.notificationService.pushToken,
    };

    if (Capacitor.isNativePlatform()) {
      const info = await App.getInfo();
      userProps['build'] = info.build; // Add the build version if it is native
    }

    this.updateUser(userProps)
      .subscribe(
        async (data: any) => {
          if (data instanceof HttpResponse) {
            if (data.status == 200) {
              console.debug(`push token is saved successfully`);
            }
            else {
              console.error(`Success but not 200`);
              this.notificationService.showToastwithoutOK(`It looks success but not correct data`, true);
            }
          }
          else {
            console.error(`Success but not HttpResponse`);
            this.notificationService.showToastwithoutOK(`It looks success but not correct response`, true);
          }
        },
        async (error: any) => {
          if (error instanceof HttpErrorResponse) {
            console.error(`Error message ${error.message}`);
            this.notificationService.showToastwithoutOK(error.message, true);
          }
          else {
            console.error(`Failed but not HttpErrorResponse`, error);
            //this.notificationService.showToastwithoutOK(`Unknown response`, true);
          }
        }
      );
  }

  public checkPhysioAvailability(date: string, slot: number, physioId: string): Observable<any> {
    if (!this.configService.getUrl("calendarHandler")) {
      this.notificationService.showToastwithoutOK("Insufficient data at client to proceed");
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }
    let jsonReq = {
      action: 'CHECK_AVAILABILITY',
      payload: {
        physioId: physioId,
        slot: slot,
        date: date
      }
    };
    return this.httpClient.post(this.configService.getUrl("calendarHandler"),
      jsonReq, { observe: 'response' });
  }

  checkTreatmentAvailability(physioId: string, date: string, slot: number, noOfSessions: number) {
    if (!this.configService.getUrl("calendarHandler")) {
      this.notificationService.showToastwithoutOK("Insufficient data at client to proceed");
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }

    let jsonReq = {
      action: 'CHECK_AVAILABILITY_FOR_TREATMENT', // CHECK_AVAILABILITY_FOR_TREATMENT
      payload: {
        physioId: physioId,
        date: date,
        slot: slot,
        noOfSessions: noOfSessions
      }
    };
    return this.httpClient.post(this.configService.getUrl("calendarHandler"),
      jsonReq, { observe: 'response' });
  }

  acceptproposedTreatment(patientId: string) {

    if (!this.configService.getUrl("patientHandler")) {
      this.notificationService.showToastwithoutOK("Insufficient data at client to proceed");
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }

    let jsonReq = {
      action: 'ADD_TREATMENT',
      payload: {
        patientId: patientId
      }
    };

    return this.httpClient.post(this.configService.getUrl("patientHandler"),
      jsonReq,
      { observe: 'response' });
  }

  createPayment(payment: any) {
    const paymentReq = {
      action: 'CREATE',
      payload: payment
    };
    return this.httpClient.post(this.configService.getUrl("paymentHandler"), paymentReq,
      { observe: 'response' });

  }

  updatePayment(payment: any) {
    const paymentReq = {
      action: 'UPDATE',
      payload: {
        id: payment.id,
        pg: payment.pg,
        status: 'Completed'
      }
    };
    return this.httpClient.post(this.configService.getUrl("paymentHandler"), paymentReq,
      { observe: 'response' });

  }


  addTreatmentToBooking(bookingId: string, paymentId: string) {
    const bookingPayload = {
      action: 'ADD_TREATMENT',
      payload: {
        bookingId: bookingId,
        paymentId: paymentId
      }
    };
    return this.httpClient.post(this.configService.getUrl("bookingHandler"), bookingPayload,
      { observe: 'response' });
  }

  getService(patientId: string) {

    if (!this.configService.getUrl("patientHandler")) {
      this.notificationService.showToastwithoutOK("Insufficient data at client to proceed");
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }

    const payload = {
      action: 'GET_SERVICE_CODE',
      payload: {
        patientId: patientId
      }
    };

    return this.httpClient.post(this.configService.getUrl("patientHandler"), payload,
      { observe: 'response' });
  }

  async getFAQs() {
    this.accessToken = await this.getToken();
    if (!this.FAQs$) {

      this.FAQs$ = this.httpClient.get(environment.firebaseConfig.databaseURL + '/patient-howitworks.json?auth=' + this.accessToken)
        .pipe(
          tap(console.log),
          shareReplay(1),
          tap(() => {
            // a log to notify it is after shareReplay
          })
        );

      return this.FAQs$;
    }
    return this.FAQs$;
  }

  cancelBooking(bookingId: string) {
    if (!this.configService.getUrl("bookingHandler")) {
      this.notificationService.showToastwithoutOK("Insufficient data at client to proceed");
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }

    const payload = {
      action: 'CANCEL',
      payload: {
        bookingId: bookingId
      }
    };

    return this.httpClient.post(this.configService.getUrl("bookingHandler"), payload,
      { observe: 'response' });
  }

  // async validateUser() {
  //   let response;
  //   let user: User;
  //   this.notificationService.showLoading(`Validating user`);
  //   try {
  //     response = await this.getUsers([getAuth().currentUser.uid]).toPromise();
  //   } catch (error) {
  //     this.notificationService.dismissLoading();
  //     this.notificationService.showToastwithoutOK(`Unable to retrieve users`, true);
  //     return;
  //   }
  //   if (!response || !response.body) {
  //     this.notificationService.dismissLoading();
  //     this.notificationService.showToastwithoutOK(`Unable to fetch user`, true);
  //     return;
  //   }
  //   user = response.body[0];
  //   if (!user.isPhoneNumberVerified) {
  //     this.notificationService.showToastwithoutOK(`Phone number is not available. Please add in profile`, true);
  //     await this.notificationService.dismissLoading();
  //     return;
  //   }
  //   await this.notificationService.dismissLoading();
  //   return user;
  // }

  addRole() {
    if (!this.configService.getUrl("manageUser")) {
      this.notificationService.showToastwithoutOK("Insufficient data at client to proceed");
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }

    let jsonReq = {
      action: 'ADD_ROLE',
      payload: {
        isPhysio: false
      }
    };

    return this.httpClient.post(this.configService.getUrl("manageUser"),
      jsonReq, { observe: 'response' });
  }

  getPhoneNumberByOtplessToken(token: string) {
    const otplessPayload = {
      action: 'GET_USER',
      payload: {
        token: token
      }
    };
    return this.httpClient.post(this.configService.getUrl("manageOtp"),
      otplessPayload, { observe: 'response' });
  };

}
