import { Injectable } from '@angular/core';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, child, get } from "firebase/database";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  urlsMap = new Map<string, any>();
  propsMap = new Map<string, any>();
  probDescMap = new Map<string, any>();
  slots = new Array();
  private _token: string;


  constructor() {
    console.debug(`ConfigService constructor`);
  }

  async loadConfigs() {
    await this.loadToken();
    await this.loadConfig("/config/probDesc");
    await this.loadConfig("/config/urls");
    await this.loadConfig("/config/slots");
    await this.loadConfig("/config/props");
  }

  public get token(): string {
    return this._token;
  }
  public set token(value: string) {
    this._token = value;
  }

  async loadToken() {
    this.token = await getAuth().currentUser.getIdToken();
  }

  getUrl(name: string) {
    return this.urlsMap.has(name) ? this.urlsMap.get(name) : null;
  }

  getProp(name: string) {
    return this.propsMap.has(name) ? this.propsMap.get(name) : null;
  }

  getProbDesc(name: string) {
    return this.probDescMap.has(name) ? this.probDescMap.get(name) : null;
  }

  loadUrls() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `/config/urls`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        Object.entries(data).forEach(([key, value]) => {
          this.urlsMap.set(key, value);
        })
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  getSlots() {
    return this.slots ? this.slots : null;
  }

  async loadConfig(configName: string) {
    const dbRef = ref(getDatabase());
    try {
      const snapshot = await get(child(dbRef, configName));
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (configName == "/config/urls") {
          Object.entries(data).forEach(([key, value]) => {
            this.urlsMap.set(key, value);
          })
          console.debug(`url's are loaded`);
        }

        else if (configName == "/config/slots") {
          this.slots = data;
          console.debug(`slots are loaded`);
        }

        else if (configName == "/config/props") {
          Object.entries(data).forEach(([key, value]) => {
            this.propsMap.set(key, value);
          })
          console.debug(`props are loaded`);
        }

        else if (configName == "/config/probDesc") {
          Object.entries(data).forEach(([key, value]) => {
            this.probDescMap.set(key, value);
          })
          console.debug(`props are loaded`);
        }
      } else {
        console.error("No data available");
      }
    } catch (error) {
      console.error(`Error while loading configs ${configName}`, error);
    }
  }
}
