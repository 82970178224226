// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: "0.0.1",
  firebaseConfig: {
    apiKey: "AIzaSyA7E2k7zJAZr3r4wtBxrv6nY5GFBJQF_rU",
    authDomain: "ngfizio-dev.firebaseapp.com",
    databaseURL: "https://ngfizio-dev.firebaseio.com",
    projectId: "ngfizio-dev",
    storageBucket: "ngfizio-dev.appspot.com",
    messagingSenderId: "578811725099",
    appId: "1:578811725099:web:1f65f038f74c0b0160f16e",
    measurementId: "G-24Z3PJ4QJG"
  },
  google_webclient: "578811725099-jueqb2ina0nt1n8k4fs7m1n8mdc5nd3c.apps.googleusercontent.com",
  default_image: "https://i.imgur.com/XFma4Yz.png",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
