import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable, throwError } from 'rxjs';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { NotificationService } from './notification.service';
import { PaymentGatewayComponent } from '../common/payment-gateway/payment-gateway.component';

/**
 * This service handles payment gateway actions by user.
 */
@Injectable({
  providedIn: 'root'
})
export class PGService {

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private notificationService: NotificationService,
  ) { }

  createOrder(payLoad: any): Observable<any> {

    const jsonReq = {
      action: 'CREATE_ORDER',
      payload: payLoad
    };
    return this.httpClient.post(this.configService.getUrl("pgAPI"),
      jsonReq, { observe: 'response' });
    // https://stackoverflow.com/questions/74833706/why-angular-14-async-http-client-call-doesnt-await-to-get-response
    // https://blog.angulartraining.com/three-reasons-to-use-the-tap-operator-from-rxjs-6828fcf288ec

  }

  verifyPayment(verifyRequest: any): Observable<any> {
    const jsonReq = {
      action: 'VERIFY_PAYMENT',
      payload: verifyRequest
    };
    return this.httpClient.post(this.configService.getUrl("pgAPI"),
      jsonReq, { observe: 'response' });
  }


  /**
   * This is phonepe service for sending UPI collect request
   * @param request 
   * @returns 
   */
  sendUPICollect(vpa: string, amount: number, txnId: string, mobileNumber: string): Observable<any> {

    if (!this.configService.getUrl("phonepePgAPI")) {
      console.error(`URL is missing`);
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }

    const payload = {
      vpa: vpa,
      amount: amount, txnId: txnId, mobileNumber: mobileNumber
    };
    const jsonReq = {
      action: 'SEND_UPI_COLLECT',
      payload: payload
    };
    return this.httpClient.post(this.configService.getUrl("phonepePgAPI"),
      jsonReq, { observe: 'response' });
  }

  checkPhonepePaymentStatus(paymentId: string) {
    if (!this.configService.getUrl("phonepePgAPI")) {
      console.error(`URL is missing`);
      return throwError({ messsage: "Insufficient data at client to proceed", status: 400 });
    }

    const payload = {
      txnId: paymentId
    };

    const jsonReq = {
      action: 'CHECK_STATUS',
      payload: payload
    };
    return this.httpClient.post(this.configService.getUrl("phonepePgAPI"),
      jsonReq, { observe: 'response' });
  }

  async launchPG(paymentId: string, amount: number, email: string, contact: number) {
    const pgModal = await this.modalCtrl.create({
      component: PaymentGatewayComponent,
      breakpoints: [0, 0.2, 0.5, 0.75],
      initialBreakpoint: 0.5,
      handle: true,
      canDismiss: async (data?: any, role?: string) => {
        if (role === 'backdrop') {
          const actionSheet = await this.actionSheetCtrl.create({
            header: 'Are you sure to Cancel payment?',
            buttons: [
              {
                text: 'Yes',
                role: 'confirm',
              },
              {
                text: 'No',
                role: 'cancel',
              },
            ],
          });

          actionSheet.present();
          const { role } = await actionSheet.onWillDismiss();
          return new Promise<boolean>(resolve => resolve(role === "confirm"));
        }
        return new Promise<boolean>(resolve => resolve(role === "close" || role === "success"));
      },
      componentProps: {
        'amount': amount,
        'for': 'Consultation',
        'email': email,
        'contact': contact,
        'paymentId': paymentId
      },
    });
    pgModal.present();
    const { data, role } = await pgModal.onWillDismiss();
    if (role !== 'success') {
      this.notificationService.showAlertWithOk("Payment failure", "Something is wrong. No worries, The deducted money if any would get refunded. Please retry");
      return;
    }
    this.notificationService.showToastwithoutOK(`Payment is successfully captured`);
    return { data, role };
  }

  getPhonepeRequest(txnId: string, amount: number, mobileNumber: string) {
    const jsonReq = {
      action: 'PAYREQUEST',
      payload: {
        amount : amount * 100, // pg needs amount in paisa
        txnId: txnId,
        mobileNumber: mobileNumber
      }
    };
    return this.httpClient.post(this.configService.getUrl("phonepePgAPI"),
      jsonReq, { observe: 'response' });
  }
}


