import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { PGService } from 'src/app/services/pg.service';
import { Subject, timer } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { PhonePePaymentPlugin } from 'phonepe-payment-capacitor';
import { Capacitor } from '@capacitor/core';
import { ClipboardService } from 'src/app/services/clipboard.service';

@Component({
  selector: 'app-phonepe',
  templateUrl: './phonepe.component.html',
  styleUrls: ['./phonepe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhonepeComponent implements OnInit {
  vpa: string;
  @Input() params: Map<string, any>;
  @Output() onPaymentDone = new EventEmitter<{ isSuccess: boolean, data?: any }>();
  payInProgress: boolean = false;
  showCounter: boolean = false;
  counter: number = 0;
  timerDone$ = new Subject<boolean>();

  counterRef: any;
  state: string;
  config: any;
  constructor(
    private pgService: PGService,
    private configService: ConfigService,
    private userService: UserService,
    private notificationService: NotificationService,
    private clipboard: ClipboardService
  ) { }

  ngOnInit() {
    this.state = 'Initiating the payment';
    this.config = this.configService.getProp('phonepe');
    console.debug(`In PhonepeComponent`);
    if (Capacitor.getPlatform() === 'web') {
      this.notificationService.showToastwithoutOK(`PhonePePaymentPlugin cannot be initialise on web`, true);
      return;
    }
    PhonePePaymentPlugin.init({
      environment: this.config.env,
      merchantId: this.config.merchantId,
      appId: this.config.appId,
      enableLogging: false
    }).then(async result => {
      console.log(`VS: ${JSON.stringify(result['status'])}`);
      if (this.config.forSignature) { // This is generate signature and no transaction happens
        PhonePePaymentPlugin.getPackageSignatureForAndroid().then(result => {
          console.debug('VS: ' + JSON.stringify(result['status']));
          this.clipboard.copy(`Signature: ${JSON.stringify(result['status'])}`);
          this.notificationService.showAlertWithOk(`Signature`, `${JSON.stringify(result['status'])}`);
        }).catch(error => {
          this.notificationService.showAlertWithOk(`Error while getting sign`, `${error.message}`);
        })
        return;
      }
      this.startTransaction();
    }).catch(error => {
      console.log('VS: error:' + error.message);
      this.notificationService.showToastwithoutOK(`Unable to initilaise phonepePayment. Please retry`, true);
      this.onPaymentDone.emit({ isSuccess: false });
    });
  }

  async startTransaction() {
    this.state = 'Starting the payment';
    let phonepeReq;
    try {
      phonepeReq = await this.getPhonepeRequest();
    } catch (error) {
      console.error(`Error while getting phonepe request: `, error);
    }

    if (!phonepeReq || !phonepeReq.base64Req || !phonepeReq.checksum) {
      console.error(`phonepe request is empty`);
      this.notificationService.showToastwithoutOK(`Something wrong in preparing payment request. Please retry`, true);
      return;
    }
    PhonePePaymentPlugin.startPGTransaction(
      {
        body: phonepeReq.base64Req,
        checksum: phonepeReq.checksum,
        apiEndPoint: '/pg/v1/pay',
        headers: { 'Content-Type': 'application/json' },
        packageName: 'com.app.ngfizio.patient',
        callBackURL: ''
      }
    ).then(result => {
      console.debug('VS: ' + JSON.stringify(result));
      if (result && result['status'] && result['status'] === 'SUCCESS') {
        this.showTimer();
      }
      else {
        this.notificationService.showAlertWithOk(`Error : ${JSON.stringify(result)}`, `Failed to initiate payment`);
        this.notificationService.showToastwithoutOK(`Received failure status from phonepePayment. Please retry`, true);
        this.onPaymentDone.emit({ isSuccess: false });
      }
    }).catch(error => {
      console.debug('VS: error:' + error.message);
      this.notificationService.showToastwithoutOK(`Unable to start phonepePayment. Please retry`, true);
      this.onPaymentDone.emit({ isSuccess: false });
    })
  }


  showTimer() {
    this.state = 'Verifying the payment';
    this.showCounter = true;
    this.payInProgress = true;
    this.counter = this.config.timeout;
    timer(1000, 1000)
      .pipe(
        takeUntil(this.timerDone$)
      )
      .subscribe({
        next: (count) => {
          this.counter--;
        },
        complete: () => {
          console.debug('timer completed!')
        }
      });

    timer(this.config.initialTimeInterval, this.config.periodicTimeInterval).pipe(
      takeUntil(this.timerDone$)
    )
      .subscribe({
        next: (count) => {
          this.getPaymentStatus();
        },
        complete: () => {
          console.debug('timer completed!')
        }
      }
      );

  }

  validatePhonePePayment() {
    this.timerDone$.next(true);
    this.pgService.checkPhonepePaymentStatus(this.params.get('paymentId'))
      .subscribe(
        async (data: any) => {
          if (data instanceof HttpResponse) {
            if (data.status == 200) {
              console.debug(`response ${JSON.stringify(data.body)}`);
              if (data.body.status === 200) { // phonepe response
                this.onPaymentDone.emit({ isSuccess: true, data: data.body.data });
                return;
              }
            } else {
              console.error(`Something is wrong : ${data.status}. status is not 200`);
            }
          }
          else {
            console.error(`Something is wrong : data is not HttpResponse`);
          }
          this.payInProgress = false;
          this.onPaymentDone.emit({ isSuccess: false });
        },
        async (error: any) => {
          this.payInProgress = false;
          if (error instanceof HttpErrorResponse) {
            console.error(`received http error res: `, error);
          }
          else {
            console.error(`received non http error res: `, error);
          }
          this.onPaymentDone.emit({ isSuccess: false });
        }
      );
  }

  getPaymentStatus() {
    this.userService.getPaymentById(this.params.get('paymentId'))
      .subscribe(
        async (data: any) => {
          if (data instanceof HttpResponse) {
            if (data.status == 200) {
              console.debug(`response ${JSON.stringify(data.body)}`);
              if (data.body.status === 'Pending') {
                return;
              }

              else if (data.body.status === 'Completed') {
                this.timerDone$.next(true);
                this.payInProgress = false;
                setTimeout(() => {
                  this.onPaymentDone.emit({ isSuccess: true, data: data.body });
                }, 2000);
                return;
              }
              else if (data.body.status === 'Failed') {
                console.error(`Error while verifying the payment status`);
                this.notificationService.showToastwithoutOK(`Error while verifying the payment status`, true);
                this.timerDone$.next(true);
                this.onPaymentDone.emit({ isSuccess: false });
                return;
              }

            } else {
              console.error(`Something is wrong : ${data.status}. status is not 200`);
              this.notificationService.showToastwithoutOK(`Something is wrong : ${data.status}. status is not 200`, true);
              this.onPaymentDone.emit({ isSuccess: false });
              return;
            }
          }
          else {
            console.error(`Something is wrong : data is not HttpResponse:`, data);
          }
        },
        async (error: any) => {
          if (error instanceof HttpErrorResponse) {
            console.error(`received http error res: `, error);
          }
          else {
            console.error(`received non http error res: `, error);
          }
          this.onPaymentDone.emit({ isSuccess: false });
        }
      );
  }

  getPhonepeRequest() {
    const txnId = this.params.get('paymentId');
    const amount = this.params.get('amount');
    const mobileNumber = this.params.get('contact');

    const promise = new Promise<void>((resolve, reject) => {
      this.pgService.getPhonepeRequest(txnId, amount, mobileNumber)
        .subscribe(
          async (data: any) => {
            await this.notificationService.dismissLoading();
            if (data instanceof HttpResponse) {
              if (data.status == 200) {
                console.debug(`response ${data.body}`);
                resolve(data.body);
              } else {
                console.error(`Something is wrong : ${data.status}. status is not 200`);
                this.notificationService.showToastwithoutOK('Something is wrong : status ${data.status} We will check it', true);
                reject({ message: `Something is wrong : ${data.status}. status is not 200` });
              }
            }
            else {
              reject({ message: `Something is wrong : data is not HttpResponse` });
            }
          },
          async (error: any) => {
            await this.notificationService.dismissLoading();
            if (error instanceof HttpErrorResponse) {
              this.notificationService.showToastwithoutOK(error.message, true);
            }
            else {
              this.notificationService.showToastwithoutOK(error.message, true);
            }
            reject(error);
          }
        )
    });
    return promise;
  }
}
