import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { User } from '../models/i-user';
import { Constants } from '../common/constants';
import { FileService } from './file.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private win: any = window;
  private user: User = {
    id: '',
    displayName: '',
    email: '',
    phoneNumber: 0
  };

  constructor(
    private fileService: FileService,
  ) { }
  loginToFirebaseWithGoogle(googleUser: any) {
    const firecreds = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
    return firebase.auth().signInWithCredential(firecreds);
  }

  async handleAuthResponse(user: firebase.User) {
    this.user.displayName = user.displayName;
    this.user.id = user.uid;
    this.user.email = user.email;
    this.user.phoneNumber = parseInt(user.phoneNumber);
    const filePath = await this.fileService.saveToPhotos(user.uid, user.providerData[0].photoURL);
    if (filePath) {
      this.user.picUrl = this.win.Ionic.WebView.convertFileSrc(this.fileService.getPhotosPath() + '/' + user.uid + Constants.PHOTOS_EXTN);
    }
  }

  loginToFirebaseWithEmailAndPwd(email: string, pwd: string) {
    return firebase.auth().signInWithEmailAndPassword(email, pwd);
  }

  async logOut() {
    //await GoogleAuth.signOut();    
    return firebase.auth().signOut();
  }


}
