import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,

  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.configService.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.configService.token}`
        }
      });
    } else {
      console.error("token is missing");
      throw new Error("token is missing");

    }

    return next.handle(request)
      .pipe(tap(
        () => {

        }
        ,
        async (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            await this.authService.logOut();
            this.router.navigate(['/init']);
          }
        }));
  }
}
