import { Component, NgZone } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { AlertController, Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './services/notification.service';
import { Location } from '@angular/common';
import { ConfigService } from './services/config.service';
import { getAuth } from 'firebase/auth';
import { SwUpdate, VersionEvent } from '@angular/service-worker';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentPageTitle = 'Dashboard';

  appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Profile',
      url: '/profile',
      icon: 'person'
    },
    {
      title: 'Bookings',
      url: '/bookings',
      icon: 'bag-handle'
    },
    {
      title: 'Payments',
      url: '/payments',
      icon: 'card'
    },
    {
      title: 'How it works',
      url: '/howitworks',
      icon: 'help'
    },
    {
      title: 'About',
      url: '/about',
      icon: 'information'
    }
  ];
  constructor(
    private platform: Platform,
    private router: Router,
    private dataService: DataService,
    private auth: AuthService,
    private _location: Location,
    public alertController: AlertController,
    private config: ConfigService,
    readonly swUpdate: SwUpdate,
    private notificationSvc: NotificationService,
    private route: ActivatedRoute
  ) {

    this.initializeApp();
    this.backButtonHandler();
    this.checkAppUpdates();
  }

  ngOnInit(): void {
  }

  backButtonHandler() {
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.debug('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/home')) {
        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();
      }
    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.debug('Handler called to force close!');
      this.alertController.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.error(e);
      })
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      console.debug(`Platform is ready`);
    });
  }

  async logout() {

    const alert = await this.alertController.create({
      header: 'Alert!',
      message: 'Are you sure to log out?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.debug(`Cancelled the log out`);
          },
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            console.debug(`Confirmed to log out`);
          },
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'cancel') {
      return;
    }
    this.auth.logOut()
      .then(() => {
        this.router.navigateByUrl('/login', { replaceUrl: true });
      })
  }

  menuAction(page: any) {
    page && page.url && this.router.navigateByUrl(page.url, { replaceUrl: true });
  }

  showExitConfirm() {
    this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.debug('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  openWA() {
    window.open(`https://wa.me/${this.config.getProp('supportWA')}?text=I%20need%20support.%20${getAuth().currentUser.email}%20is%20my%20email`);
  }

  checkAppUpdates() {

    if (!this.swUpdate.isEnabled) {
      this.notificationSvc.showAlertWithOk(`Warning`, `Your app cannot be updated automatically`);
      return;
    }

    this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
      switch (event.type) {
        //event.
        case 'VERSION_DETECTED':
          //console.log(`Downloading new app version: ${evt.version.hash}`);
          this.notificationSvc.showToastwithoutOK(`A new version is available and downloading is in progress`);
          break;
        case 'VERSION_READY':
          this.notificationSvc.showToastwithoutOK(`A new version is available and installing`);
          this.swUpdate.activateUpdate()
            .then(() => document.location.reload())
            .catch(error => console.error('Failed to apply updates:', error));
          break;
        case 'VERSION_INSTALLATION_FAILED':
          //console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          this.notificationSvc.showAlertWithOk(`Update error`, `Failed to updated`);
          break;
      }
    });

  }
}
