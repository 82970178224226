import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController, ToastOptions } from '@ionic/angular';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  loading: HTMLIonLoadingElement;
  otpless$: Subject<string>;
  private _pushToken: string;
  _pushToken$: ReplaySubject<boolean>;

  constructor(
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
    private alertController: AlertController
  ) {
    this.otpless$ = new Subject<string>();
    this._pushToken$ = new ReplaySubject<boolean>(1);
    //this.initFcm();
  }

  async showToastwithoutOK(msg: any, isFailure?: boolean, duration?: number) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'bottom',
      duration: duration == null ? 2000 : duration,
      color: isFailure ? 'danger' : 'success'
    });
    toast.present();
  }

  async showLoading(message: string) {
    this.loading = await this.loadingCtrl.create({
      message: message,
    });

    this.loading.present();
  }

  async dismissLoading() {
    this.loading.dismiss();
  }

  async presentAlert(msg: string, header: string) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();
  }

  getotpless(): Observable<string> {
    return this.otpless$.asObservable();
  }

  getpushToken(): Observable<boolean> {
    return this._pushToken$.asObservable();
  }

  sendwaId(id: string) {
    this.otpless$.next(id);
  }

  initFcm() {

    if (Capacitor.getPlatform() === 'web') {
      this.showToastwithoutOK(`Notification cannot be registered on web`, true);
      return;
    }

    const addListeners = async () => {
      await PushNotifications.addListener('registration', token => {
        console.debug('Registration token: ', token.value);
        this.pushToken = token ? token.value : null;
        this.pushToken && this._pushToken$.next(true);
      });

      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
        this.showToastwithoutOK(`notification registrationError`, true);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
      });

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
    }

    const registerNotifications = async () => {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        this.showToastwithoutOK(`User denied permissions`, true);
        return;
      }

      await PushNotifications.register();
    }

    const getDeliveredNotifications = async () => {
      const notificationList = await PushNotifications.getDeliveredNotifications();
      console.log('delivered notifications', notificationList);
    }
    addListeners();
    registerNotifications();
  }

  public get pushToken(): string {
    return this._pushToken;
  }
  public set pushToken(value: string) {
    this._pushToken = value;
  }

  showAlertWithOk(headerMessage: string, message: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.alertController.create({
        header: headerMessage,
        message: message,
        buttons: [
          {
            text: 'Okay',
            handler: () => {
              resolve(true);
            }
          }
        ]
      }).then(res => {
        res.present();
      });
    });
  }

  async showAlertWithConstent(headerMessage: string, message: string) {
    return await this.alertController.create({
      header: headerMessage,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
        },
      ],
    });
  }

}
