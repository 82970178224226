import { Injectable } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Platform } from '@ionic/angular';
import { Constants } from '../common/constants';
import { Filesystem, Directory, Encoding, GetUriResult } from '@capacitor/filesystem';
import { DataService } from './data.service';
//const APP_DIRECTORY = Directory.Documents;


@Injectable({
  providedIn: 'root'
})
export class FileService {

  photosPath: any;
  homePath: any;
  fileTransferObj: FileTransferObject;
  uriRes: GetUriResult;

  constructor(
    public file: File,
    public fileTransfer: FileTransfer,
    private afStorage: AngularFireStorage,
    private platform: Platform,
    private dataService: DataService
  ) {
    this.platform.ready().then(async () => {

      if (this.dataService.getValue("platform") === "android") {
        try {
          await Filesystem.mkdir({
            directory: Directory.Data,
            path: Constants.PHOTOS,
            recursive: true
          });
        } catch (error) {
          console.error("Erroring in creating directory", error);
        }
        try {
          let uriRes = await Filesystem.getUri({
            directory: Directory.Data,
            path: Constants.PHOTOS,
          });
          this.photosPath = uriRes.uri;
          console.log("photo_path " + this.photosPath);
        } catch (error) {
          console.error("error while getting photos path ", error);
        }
      }
      this.fileTransferObj = this.fileTransfer.create();
    });
  }

  saveToPhotos(key: any, url: any): any {
    if (this.photosPath) {
      return this.fileTransferObj.download(url, this.photosPath + '/' + key + Constants.PHOTOS_EXTN)
        .then((entry) => {
          console.log('download complete: ' + entry.toURL());
          //this.saveToStorage(key + Constants.PHOTOS_EXTN, key + '/image.jpeg');
          return entry;
        })
        .catch((error) => {
          console.log('error in downloading the file ' + JSON.stringify(error));
          return error;
        });
    }
  }

  isFilePresent(path: string): Promise<GetUriResult> {
    return Filesystem.getUri({
      directory: Directory.Data,
      path: path,
    });
  }

  public getPhotosPath() {
    return this.photosPath;
  }

  saveToStorage(name: any, storagePath: any) {
    console.log('Uploading to storage');
    this.file.readAsDataURL(this.photosPath, name)
      .then((base64File) => {
        try {
          var blob = this.b64toBlob(base64File, null, null);
          const task = this.afStorage.upload(storagePath, blob);
          task.percentageChanges().subscribe((value) => {
            console.log('progressBarValue ' + value.toFixed(2));
          });
        }
        catch (ex) {
          console.log('error in uploading saveToStorage ' + JSON.stringify(ex));
        }
      }, (err) => {
        console.log("error in saveToStorage " + JSON.stringify(err));
      });

  }
  b64toBlob(b64Data, contentType, sliceSize) {
    var contentType = contentType || '';
    var sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: "image/jpg" });
  }

}
