import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Checkout } from 'capacitor-razorpay';
import { getAuth } from 'firebase/auth';
import { ConfigService } from 'src/app/services/config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PGService } from 'src/app/services/pg.service';

// This component is only for payment gateway

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss'],
})
export class PaymentGatewayComponent implements OnInit {

  @Input() amount: number;
  @Input() for: string;
  @Input() email: string;
  @Input() contact: string;
  @Input() paymentId: string;
  pg: string;
  params: Map<string, any>;

  constructor(
    private pgService: PGService,
    private notificationService: NotificationService,
    private configService: ConfigService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.params = new Map();
    this.params.set('amount', this.amount);
    this.params.set('email', this.email);
    this.params.set('contact', this.contact);
    this.params.set('paymentId', this.paymentId);
    this.params.set('for', this.for);
    this.pg = this.configService.getProp('pg');
    //this.createOrder();
  }

  async createOrder() {
    let pgPayload = {
      amount: this.amount * 100, // razorpay need the amount in paisa
      currency: "INR",
      notes: {
        uid: getAuth().currentUser.uid,
        time: (new Date()).getTime()
      }
    };
    await this.notificationService.showLoading('Initiating payment');
    this.pgService.createOrder(pgPayload)
      .subscribe(
        async (data: any) => {
          await this.notificationService.dismissLoading();
          if (data instanceof HttpResponse) {
            if (data.status == 200) {
              this.checkOut(data.body);
            }
            else {
              this.notificationService.showToastwithoutOK(`It seems successful but not enough data`);
            }
          }
          else {
            this.notificationService.showToastwithoutOK(`It seems successful but not correct type`);
          }
        },
        async (error: any) => {
          await this.notificationService.dismissLoading();
          if (error instanceof HttpErrorResponse) {
            this.notificationService.showToastwithoutOK(error.message);
          }
          else {
            this.notificationService.showToastwithoutOK(`Some unknown error ${error.message}`);
          }
        }
      );
  }

  async checkOut(orderResponse: any) {
    const options = {
      key: this.configService.getProp('rzpKeyId'),
      amount: orderResponse.amount,
      description: this.for,
      image: this.configService.getProp('iconUrl'),
      order_id: orderResponse.id,
      currency: orderResponse.currency,
      name: this.configService.getProp('iconName'),
      prefill: {
        email: this.email,
        contact: this.contact
      },
      theme: {
        color: '#3399cc'
      }
    }
    try {
      let data = (await Checkout.open(options));
      this.verifyPayment(data.response, orderResponse.id);
    } catch (error) {
      let errorObj;
      try {
        errorObj = JSON.parse(error['code']);
      } catch (error) {
        console.error(`Unable to parse error : `, error);
      }
      this.modalCtrl.dismiss(errorObj, 'failure');
    }
  }


  async verifyPayment(checkoutResponse: any, order_id: string) {
    await this.notificationService.showLoading('Verifying payment');
    let payLoad = {
      orderId: order_id,
      razorpayPaymentId: checkoutResponse.razorpay_payment_id,
      razorpaySignature: checkoutResponse.razorpay_signature
    };
    this.pgService.verifyPayment(payLoad)
      .subscribe(
        async (data: any) => {
          await this.notificationService.dismissLoading();
          if (data instanceof HttpResponse) {
            if (data.status == 200) {
              this.modalCtrl.dismiss(payLoad, 'success');
            }
            else {
              this.notificationService.showToastwithoutOK(`It seems successful but not enough data`);
              this.modalCtrl.dismiss(null, 'failure');
            }
          }
          else {
            this.notificationService.showToastwithoutOK(`It seems successful but not correct type`);
            this.modalCtrl.dismiss(null, 'failure');
          }
        },
        async (error: any) => {
          await this.notificationService.dismissLoading();
          if (error instanceof HttpErrorResponse) {
            this.notificationService.showToastwithoutOK(error.message);
          }
          else {
            this.notificationService.showToastwithoutOK(`Some unknown error ${error.message}`);
          }
          this.modalCtrl.dismiss(error, 'failure');
        }
      );
  }

  async paymentDone(event: any) {
    if (event.isSuccess) {
      await this.modalCtrl.dismiss(event.data, "success");
    }
    else {
      await this.modalCtrl.dismiss({}, "close");
    }
  }

}
