import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _storage: Storage | null = null;
  private dataMap = new Map();

  constructor(
    private storage: Storage
  ) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  setValue(key: string, value: any) {
    this.dataMap.set(key, value);
  }

  getValue(key: string) {
    return this.dataMap.has(key) ? this.dataMap.get(key) : null;
  }

  public save(key: string, value: any) {
    this._storage?.set(key, value);
  }

  public async fetch(key: string) {
    return await this._storage?.get(key);
  }


}
