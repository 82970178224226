import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(
    private clipboard: Clipboard,
    private notfication: NotificationService) { }

  copy(text: string) {
    this.clipboard.copy(text);
    this.notfication.showToastwithoutOK(`Copied`);
  }
}
